<template>
    <v-container class="mb-3">
        <AdBTWR v-if="!user.isAdFree"/>

        <v-row align="center" class="mt-15">
            <v-col cols="12">
                <Login v-on="$listeners" :user="user"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Login from '@/components/Login.vue';
    import AdBTWR from '@/components/ads/BTWR.vue';

    export default {
        props: ['user'],

        components: { Login, AdBTWR },

        watch: {
            "user.authenticated"() {
                if (this.user && this.user.username && this.$route.name === 'login') {
                    this.$router.push({path: '/user/' + this.user.username});
                }
            }
        }
    }
</script>
